import React, { useEffect, useState } from 'react'
import Services from '../Services'

const Us = ({ languaje, refers }) => {
    const [usData, setUsData] = useState(null)
    useEffect(() => {
        async function fetchData() {
          await Services.getUs(languaje)
                .then(async (data) => {
                    setUsData(data.data.data[0])
                })
        }
        fetchData()
      // eslint-disable-next-line
      }, [languaje])
    return (
        <div className="container" style={{ backgroundColor: 'white',maxWidth:1540 }}>
            <h1 className="text-center mt-5 mb-5 u-bold f-75 color-title" ref={refers}>{usData?.attributes.title}</h1>
            <p className="text-justify mb-5 u-regular f-25 color-title">{usData?.attributes.body}</p>
            <div className="row mb-5">
                {
                    usData && usData.attributes.our_sections.data.map(
                        (item, index) => (
                            <div className="col-6 col-lg-3 mb-4" key={`us_${index}`}>
                                <div className="card card-cumtom shadow rounded">
                                    <div className="card-body">
                                        <div className="body-inner">
                                            <img src={`https://shark-app-uhr95.ondigitalocean.app${item?.attributes?.image?.data[0]?.attributes?.url}`} className="img-fluid" alt='lauch' />
                                            <p className="text-center mt-3 u-bold f-20 text-uppercase color-title">{item.attributes.title}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default Us