import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Context from './Context'
import Page from './Page'
import Post from './Post'

export const App = () => {
    const [languaje, changeLanguaje] = useState('es')
    const [postSearch, changePostSearch] = useState('')
    return (
        <Router>
            <Context.Provider value={{
                languaje: languaje,
                changeLanguaje: changeLanguaje,
                postSearch,
                changePostSearch
            }}>
                <Switch>
                    <Route path='/:post/:url' component={Post} />
                    <Route path='/:anchor' component={Page} />
                    <Route path='/' component={Page} />
                </Switch>
            </Context.Provider>
        </Router>
    )
}

export default App
