import React, { useEffect, useState } from 'react'
import { GrNext, GrPrevious } from 'react-icons/gr'
import Services from '../Services'

const News = ({languaje, refers, postSearch, changePostSearch}) => {
    const [news, setNews] = useState(null)
    useEffect(() => {
        async function fetchData() {
          await Services.getPosts(languaje)
                .then(async (data) => {
                    setNews(data.data.data[0])
                })
        }
        fetchData()
      // eslint-disable-next-line
      }, [languaje])
    return (
        <div className='container-fluid' ref={refers}>
            <div className="row">
                <div className='col-xs-12 col-sm-12 col-md-6 offset-md-3'>
                <h1 className="text-center mt-5 mb-5 u-bold f-75 color-title" >{news?.attributes.title}</h1>
                    <div className='carousel'>
                        <input defaultChecked className='carousel__activator' id='carousel-slide-activator-1' name='carousel' type='radio' />
                        <input className='carousel__activator' id='carousel-slide-activator-2' name='carousel' type='radio' />
                        <input className='carousel__activator' id='carousel-slide-activator-3' name='carousel' type='radio' />
                        {
                            news && news?.attributes.posts.data > 3 && <>
                                <div className='carousel__controls'>
                                    <label className='carousel__control carousel__control--forward' htmlFor='carousel-slide-activator-2'>
                                        <GrNext />
                                    </label>
                                </div>
                                <div className='carousel__controls'>
                                    <label className='carousel__control carousel__control--backward' htmlFor='carousel-slide-activator-1'>
                                        <GrPrevious />
                                    </label>
                                    <label className='carousel__control carousel__control--forward' htmlFor='carousel-slide-activator-3'>
                                        <GrNext />
                                    </label>
                                </div>
                                <div className='carousel__controls'>
                                    <label className='carousel__control carousel__control--backward' htmlFor='carousel-slide-activator-2'>
                                        <GrPrevious />
                                    </label>
                                </div>
                            </>
                        }
                        <div className='carousel__screen'>
                            <div className='carousel__track'>
                                {
                                    news && news?.attributes.posts.data.map(
                                        (item, index) => (
                                            <div key={`new_${index}`} className='carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-3'>
                                                <div className='demo-content'>
                                                    <img src={`https://shark-app-uhr95.ondigitalocean.app${item.attributes?.imageCover.data[0].attributes.formats.small.url}`} className='img-fluid img-top' alt={item.attributes.title} />
                                                    <div className='p-2'>
                                                        <h5 className="card-title mt-2 mb-1" style={{height: 50}}>{item.attributes.title}</h5>
                                                        <p>{item.attributes.author} - {item.attributes.datePublished}</p>
                                                        <p className="card-text" style={{fontSize: '14px', height: 150}}>{item.attributes.summary}</p>
                                                        <p className='text-right mb-0'><a className='text-white' href={`/${languaje === 'en' ? 'news' : 'noticias'}/${item.attributes.urlSeo}`}>Leer más...</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News