import API from './Api'

// eslint-disable-next-line
export default {
    getSlides: async (lan='en') => {
        return API.get(`/slides?populate=*&locale=${lan}`)
    },
    getUs: async (lan='en') => {
        return API.get(`/ours?locale=${lan}&populate[our_sections][populate][0]=image`)
    },
    getService: async (lan='en') => {
        return API.get(`/services?locale=${lan}&populate[service_contents][populate][0]=image`)
    },
    getPosts: async (lan='en') => {
        return API.get(`/news?locale=${lan}&populate[posts][populate][0]=imageCover`)
    },
    getProjects: async (lan='en') => {
        return API.get(`/projects?locale=${lan}&populate[posts][populate][0]=imageCover`)
    },
    getConfs: async (lan='es') => {
        return API.get(`/confs?locale=${lan}&populate=*`)
    },
    getPost: async (lan, q) => {
        console.log('+^^', q)
        return API.get(`/posts?filters[urlSeo][$eq]=${q}&locale=${lan}&populate=*`)
    },
}

//6608ef36e53b06855bb5f7405863c4a3626055aeb9530ef095a489f8650f98f499b10afd52e07b86808786a3e2156c5babaa501444d2a72518f70b9a221b1f4b351b9cdf02afda48d2aa4944069ae700703e6297aebced1f8e3ac5879b2a4fe69ffbf77f8aa2c4e31f6d88b3f6e5394209cb3f90a85f5ec05024215c7124b78c