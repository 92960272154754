import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import Services from '../Services';
import i18n from '../i18n';


const Header = ((props) => {
    const { languaje, setlanguaje, refers, conf } = props
    const [slides, setSlides] = useState([])

    useEffect(() => {
        async function fetchData() {
          await Services.getSlides(languaje)
                .then(async (data) => {
                    setSlides(data.data.data)
                    console.log('data', data.data.data)
                })
        }
        fetchData()
      // eslint-disable-next-line
      }, [languaje])
    const changeLang = (event) => {
		setlanguaje(event.target.value);
        i18n.changeLanguage(event.target.value);
	}
    return (
        
            <Carousel ref={refers}>
                {
                    slides.map((item, index) => (
                        <Carousel.Item
                            key={`corousel-${index}`} 
                            style={{
                                background: 'none',
                                backgroundImage: `url(https://shark-app-uhr95.ondigitalocean.app${item?.attributes?.image?.data[0]?.attributes?.url})`
                            }}
                        >
                            <Carousel.Caption>
                                <h3>{item.attributes.caption}</h3>
                                <p>{item.attributes.title}</p>
                                <div  className="d-none d-sm-block">
                                    <div className="div-icons div-left">
                                        <div className="border-social">
                                            {
                                                conf && conf.attributes?.social?.data.map(
                                                    (e, i) => (<a href={e.attributes.value} target='_blank' rel="noopener noreferrer"><i className={`fab fa-${e.attributes.label} icon-footer ${i === 0 ? 'mal40' : ''}`}></i></a>)
                                                )
                                            }
                                        </div>	
                                    </div>
                                    <div className="div-icons div-right">
                                        <div className="div-select">
                                            <select className="form-control select-custom" value={languaje} onChange={changeLang}>
                                                <option value="es">esp</option>
                                                <option value="en">eng</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>  
                    ))
                }
            </Carousel>
    )
})

export default Header