import React, { useEffect, useState } from 'react'
import API from '../Services'

const Services = ({languaje, refers}) => {
    const [service, setService] = useState(null)
    const [index, setIndex] = useState(0)
    useEffect(() => void setInterval(() => setIndex(state => (state + 1) % 4), 60000), [])
    useEffect(() => {
        async function fetchData() {
          await API.getService(languaje)
                .then(async (data) => {
                    setService(data.data.data[0])
                })
        }
        fetchData()
      // eslint-disable-next-line
      }, [languaje])
    return (
        <div className="container-fluid" style={{ backgroundColor: '#F6F6F6', paddingTop: 20 }}>
            <h1 className="text-center mt-4 mb-4 u-bold f-75 color-title" ref={refers}>{service?.attributes.title}</h1>


            <div className="row">
                <div className="tab-content d-none d-sm-block">
                    {
                        service && service.attributes.service_contents.data.map(
                            (element, vindex) => (
                                <div className={`tab-pane fade ${index === vindex ? "show active" : ""}`} key={`ser_${vindex}`} role="tabpanel" style={{ justifyContent: 'center' }}>
                                    <div className='card-service'>
                                        <div className="ser-div-left">
                                            <p className="text-center u-bold f-40 color-title mb-0 title-card">{element.attributes.title}</p>
                                            <p className="text-justify u-regular f-20 color-title">{element.attributes.body}</p>
                                        </div>
                                        <div className="ser-div-right">
                                            <img src={`https://shark-app-uhr95.ondigitalocean.app${element?.attributes?.image?.data[0]?.attributes?.url}`} className="img-fluid img-card" alt='lauch' />
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
                <div className="tab-content d-block d-sm-none mb-1">
                    {
                        service && service.attributes.service_contents.data.map(
                            (element, vindex) => (
                                <div className={`tab-pane fade ${index === vindex ? "show active" : ""}`} key={`serr_${vindex}`} role="tabpanel" style={{ justifyContent: 'center' }}>
                                    <p className="text-center u-bold f-40 color-title mb-0 title-card">{element.attributes.title}</p>
                                    <div className='card-service-m mt-3' style={{ width: '90%', borderRadius: 20, padding: 10 }}>
                                        <img src={`https://shark-app-uhr95.ondigitalocean.app${element?.attributes?.image?.data[0]?.attributes?.url}`} className="img-fluid" style={{ borderRadius: 20 }} alt='lauch' />
                                        <p className="text-justify u-regular color-title mt-3 mb-0" style={{ fontSize: 16 }}>{element.attributes.body}</p>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div> 
            </div>


            <div className="row mb-5 d-none d-sm-block text-center mt-3">
                {
                    service && service.attributes.service_contents.data.map(
                        (element, vindex) => (
                            <button key={`bser_${vindex}`} type="button" className={`btn btn-secondary btn-lg u-regular f-20 mb-5 ${index === vindex ? "btn-active" : "btn-normal"}`} onClick={() => setIndex(vindex)}>{element.attributes.caption}</button>
                        )
                    )
                }
            </div>
            <div className="row d-block d-sm-none text-center">
                {
                    service && service.attributes.service_contents.data.map(
                        (element, vindex) => (
                            <i key={`bserr_${vindex}`} className={`fas fa-circle mb-5 ${index > 0 ? 'ml-1' :  ''} ${index === vindex ? "dot-active" : "dot-normal"}`} onClick={() => setIndex(vindex)}></i>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default Services