import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Services from '../Services'

const Projects = ({languaje, refers}) => {
    const [projects, setProjects] = useState(null)
    const history = useHistory()
    useEffect(() => {
        async function fetchData() {
          await Services.getProjects(languaje)
                .then(async (data) => {
                    setProjects(data.data.data[0])
                })
        }
        fetchData()
      // eslint-disable-next-line
      }, [languaje])
    return (
        <>
            <div className="container d-none d-sm-block" style={{ paddingTop: 20 }} ref={refers}>
                <h1 className="text-center mt-5 mb-5 u-bold f-75 color-title" >{projects?.attributes.title}</h1>
                <div className="row mt-5 pl-5 pr-5">
                    {                        
                        projects && projects?.attributes.posts.data.filter((e, i) => i < 2).map(
                            (item, index) => (
                                <div className="col" key={`pro_${index}`}>
                                    <div className="project1" onClick={() => {if (!item.attributes?.haveDetails) return; history.push(`/${languaje === 'en' ? 'projects' : 'proyectos'}/${item.attributes.urlSeo}`) }}>
                                        <img src={`https://shark-app-uhr95.ondigitalocean.app${item.attributes?.imageCover.data[0].attributes.formats.small.url}`} className="img-fluid" alt='lauch' />
                                        <h1 className="hp1 u-bold f-40">{item.attributes.title}</h1>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
                <div className="row mb-5 mt-5 pl-5 pr-5">
                    {
                        projects && projects?.attributes.posts.data.filter((e, i) => i > 1).map(
                            (item, index) => (
                                <div className="col" key={`pro_${index}`}>
                                    <div className="project1" onClick={() => {if (!item.attributes?.haveDetails) return; history.push(`/${languaje === 'en' ? 'projects' : 'proyectos'}/${item.attributes.urlSeo}`) }}>
                                        <img src={`https://shark-app-uhr95.ondigitalocean.app${item.attributes?.imageCover.data[0].attributes.formats.small.url}`} className="img-fluid" alt='lauch' />
                                        <h1 className="hp1 u-bold f-40">{item.attributes.title}</h1>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>

            <div className="container d-block d-sm-none" style={{ paddingTop: 20 }}>
                <h1 className="text-center mt-5 mb-5 u-bold f-75 color-title">{projects?.attributes.title}</h1>
                <div className="row">
                    {
                        projects && projects?.attributes.posts.data.map(
                            (item, index) => (
                                <div className="col-12" key={`prom_${index}`}>
                                    <div className="mb-4" style={{ width: '100%', display: 'grid', alignItems: 'center', justifyContent: 'center' }} onClick={() => {if (!item.attributes?.haveDetails) return; history.push(`/${languaje === 'en' ? 'projects' : 'proyectos'}/${item.attributes.urlSeo}`) }}>
                                        <img src={`https://shark-app-uhr95.ondigitalocean.app${item.attributes?.imageCover.data[0].attributes.formats.small.url}`} className="img-fluid" style={{ borderRadius: 20 }} alt='lauch' />
                                        <h1 className="hp1 u-bold f-40">{item.attributes.title}</h1>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Projects