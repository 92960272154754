import React, { useContext, useEffect, useState } from 'react'
import API from './Services'
import { Navbar, Nav } from 'react-bootstrap'
import Context from './Context'
import { useParams, useHistory } from 'react-router'
import ReactMarkdown from 'react-markdown'

import './App.css'

const Post = () => {
    const {languaje} = useContext(Context)
	let { url } = useParams()
	const history = useHistory()
    const [conf, setConf] = useState(null)
	const [body, setBody] = useState('')
    useEffect(() => {
        async function fetchData() {
          await API.getConfs(languaje)
                .then(async (data) => {
                    setConf(data.data.data[0])
                })
          await API.getPost(languaje, url)
                    .then(async (data) => {
						setBody(data.data.data[0])
                    })
        }
        fetchData()
    // eslint-disable-next-line
    }, [languaje])
    const executeScrollHOME = () => {
		history.push('/')
	}
    const executeScrollUS = () => {
		history.push(`${languaje === 'en' ? '/us' : '/nosotros'}`)
	}
    const executeScrollSERVICE = () => {
		history.push(`${languaje === 'en' ? '/services' : '/servicios'}`)
	}
    const executeScrollNEWS = () => {
		history.push(`${languaje === 'en' ? '/news' : '/noticias'}`)
	}
    const executeScrollPROJECTS = () => {
		history.push(`${languaje === 'en' ? '/projects' : '/proyectos'}`)
	}
    const executeScrollCONTACT = () => {
		history.push(`${languaje === 'en' ? '/contact' : '/contacto'}`)
	}
    return (
        <>
			<Navbar expand="lg" style={{backgroundColor: '#102541', paddingRight: 20, paddingLeft: 20}} fixed="top">
				<Navbar.Brand href="#home">
					<img src={require('./assets/img/logo.png')} height={43} width={126} alt='data mobility'></img>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto">
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'home')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollHOME}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'home')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'about')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollUS}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'about')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'services')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollSERVICE}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'services')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'news')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollNEWS}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'news')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'projects')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollPROJECTS}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'projects')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'contact')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollCONTACT}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'contact')?.attributes?.value}</span></Nav.Link>}
					</Nav>
				</Navbar.Collapse>
			</Navbar>

            
            {	body?.attributes?.body && (
					// <div dangerouslySetInnerHTML={createMarkup(body?.attributes?.body)}>
					// 	{}
					// </div>					
					<div className='container' style={{marginTop: '70px'}}>
						<div className='row'>
							<div className='col'>
								<ReactMarkdown>{ body?.attributes?.body }</ReactMarkdown>
							</div>
						</div>
					</div>
				)
			}
           

            <div className="container-fluid d-none d-sm-block footer" style={{paddingRight: 20, paddingLeft: 20}}>
				<div className="row">
					<div className="col">
						{
							conf && conf.attributes?.social?.data.map(
								e => (<a href={e.attributes.value}><i className={`fab fa-${e.attributes.label} icon-footer`}></i></a>)
							)
						}
						
						{/* <i className="fab fa-twitter icon-footer ml-3"></i>
						<i className="fab fa-instagram icon-footer ml-3"></i>
						<i className="fab fa-linkedin-in icon-footer ml-3"></i> */}
					</div>
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion") ? <div className="col text-center u-light">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion").attributes.value}</div> : null}
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone") ? <div className="col text-center u-light">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone").attributes.value}</div> : null }
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "email") ? <div className="col text-right u-light">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "email").attributes.value}</div> : null}
				</div>
			</div>

			<div className="container-fluid d-block d-sm-none footer" style={{height: 'auto'}}>
				<div className="row">
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion") ?<div className="col-12 text-center u-light mb-1">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion").attributes.value}</div> : null}
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone") ? <div className="col-12 text-center u-light mb-1">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone").attributes.value}</div> : null}
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "email") ? <div className="col-12 text-center u-light mb-1">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "email").attributes.value}</div> : null}
					<div className="col-12 text-center mb-1">
						{
							conf && conf.attributes?.social?.data.map(
								(e, i) => (<a href={e.attributes.value} target='_blank' rel="noopener noreferrer"><i className={`fab fa-${e.attributes.label} icon-footer ${i > 0 ? 'ml-3' : ''}`}></i></a>)
							)
						}
					</div>
				</div>	
			</div>
        </>
    )
}

export default Post