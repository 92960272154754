import React, { useState, useRef, useEffect, useContext } from 'react'
import { useForm } from "react-hook-form"
import './App.css'
import emailjs from 'emailjs-com'
import SweetAlert from 'react-bootstrap-sweetalert'

import { Navbar, Nav } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import Header from './components/header'
import Us from './components/us'
import Services from './components/services'
import News from './components/News'
import Projects from './components/Projects'
import API from './Services'
import Context from "./Context"
import { useParams } from 'react-router'




const scrollToRef = (ref) => window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 100 })

function Page({ t }) {	
	const {languaje, changeLanguaje, postSearch, changePostSearch} = useContext(Context)
	let { anchor } = useParams()
	const myRefFORM = useRef('form');
	const myRefHOME = useRef('home');
	const myRefUS = useRef('us');
	const myRefSERVICE = useRef('service');
	const myRefNEWS = useRef('news');
	const myRefPROJECTS = useRef('projects');
	const myRefCONTACT = useRef('contac');
	const executeScrollHOME = () => scrollToRef(myRefHOME);
	const executeScrollUS = () => scrollToRef(myRefUS);
	const executeScrollSERVICE = () => scrollToRef(myRefSERVICE);
	const executeScrollNEWS = () => scrollToRef(myRefNEWS);
	const executeScrollPROJECTS = () => scrollToRef(myRefPROJECTS);
	const executeScrollCONTACT = () => scrollToRef(myRefCONTACT);
	const [success, setSuccess] = useState(false)
	const [danger, setDanger] = useState(false)
	const { handleSubmit, register, errors } = useForm();
	const [conf, setConf] = useState(null)

	useEffect(() => {
		if (!anchor) return
		if (['us', 'nosotros'].includes(anchor)) {
			setTimeout(() => {
				executeScrollUS()
			}, 500);
		}
		if (['services', 'servicios'].includes(anchor)) {
			setTimeout(() => {
				executeScrollSERVICE()
			}, 500);
		}
		if (['news', 'noticias'].includes(anchor)) {
			setTimeout(() => {
				executeScrollNEWS()
			}, 500);
		}
		if ([ 'projects', 'proyectos'].includes(anchor)) {
			setTimeout(() => {
				executeScrollPROJECTS()
			}, 500);
		}
		if (['contact', 'contacto'].includes(anchor)) {
			setTimeout(() => {
				executeScrollCONTACT()
			}, 500);
		}
		
	}, [anchor])

	useEffect(() => {
        async function fetchData() {
          await API.getConfs(languaje)
                .then(async (data) => {
                    setConf(data.data.data[0])
                })
        }
        fetchData()
      // eslint-disable-next-line
      }, [languaje])
	
	const onSubmit = (data, e) => {
		emailjs.send('gmail', 'template_l9uABsxU', {from_name: data.name + ' ' + data.lastname, from_email: data.email, message_html: data.messages}, 'user_6Qzpcu3Xv6JNLcurVsHve')
			   	.then(res => {
					setSuccess(true);
					e.target.reset();
				})
				.catch(
					err => setDanger(true)
				)	 
	}

	const cerrarSuccess = () => {
		setSuccess(false);
		setDanger(false);
	}

	return (
		<>
			<Navbar expand="lg" style={{backgroundColor: '#102541', paddingRight: 20, paddingLeft: 20}} fixed="top">
				<Navbar.Brand href="#home">
					<img src={require('./assets/img/logo.png')} height={43} width={126} alt='data mobility'></img>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto">
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'home')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollHOME}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'home')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'about')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollUS}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'about')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'services')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollSERVICE}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'services')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'news')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollNEWS}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'news')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'projects')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollPROJECTS}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'projects')?.attributes?.value}</span></Nav.Link>}
						{conf && conf.attributes.menus.data.find(e => e.attributes.label === 'contact')?.attributes?.value && <Nav.Link className="m-auto" onClick={executeScrollCONTACT}><span className="c-white u-regular f-18">{conf.attributes.menus.data.find(e => e.attributes.label === 'contact')?.attributes?.value}</span></Nav.Link>}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			
			<Header languaje={languaje} setlanguaje={changeLanguaje} refers={myRefFORM} conf={conf} />
						
			<Us languaje={languaje} refers={myRefUS} />

			<Services languaje={languaje} refers={myRefSERVICE} />

			<News languaje={languaje} refers={myRefNEWS} postSearch={postSearch} changePostSearch={changePostSearch} />

			<Projects languaje={languaje} refers={myRefPROJECTS} />




			<div className="container-fluid img-contact" style={{ paddingTop: 40, marginTop: 100}} ref={myRefCONTACT}>
				<div className="row">
					<div className="col">
						<h1 className="title-contact u-bold f-45">{t('haveyouA')} <br /> {t('haveyouB')}</h1>
						<div className="card-form mt-5">
							<div className="form-bg">
								<form className="form" onSubmit={handleSubmit(onSubmit)} ref={myRefFORM}>
									<div className="row">
										<div className="col-12 col-sm-6">
											<div className="form-group">
												<label>{t('name')}</label> 
												<input type="text" className="form-control" name="name" ref={register({required: t('require'), pattern: {value: /^[A-Za-z]+$/i, message: t('ename') }})}/> 
												<small id="passwordHelpBlock" className="form-text text-danger">{errors.name && errors.name.message}</small>
											</div>
										</div>
										<div className="col-12 col-sm-6">
											<div className="form-group">
												<label>{t('lastname')}</label>
												<input type="text" className="form-control" name="lastname" ref={register({required: t('require'), pattern: {value: /^[A-Za-z]+$/i, message: t('elastname') }})}/> 
												<small id="passwordHelpBlock" className="form-text text-danger">{errors.lastname && errors.lastname.message}</small>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label>{t('email')}</label>
										<input type="email" className="form-control"  name="email" ref={register({required: t('require'), pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t('eemail') }})}/>
										<small id="passwordHelpBlock" className="form-text text-danger">{errors.email && errors.email.message}</small>
									</div>
			                        <div className="form-group">
										<label>{t('message')}</label>
										<textarea className="form-control" rows="2" name="messages" ref={register({required: t('require')})}></textarea>
										<small id="passwordHelpBlock" className="form-text text-danger">{errors.messages && errors.messages.message}</small>
									</div>
									<button className="btn text-center btn-blue" type="submit">{t('send')}</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="container-fluid d-none d-sm-block footer" style={{paddingRight: 20, paddingLeft: 20}}>
				<div className="row">
					<div className="col">
						{
							conf && conf.attributes?.social?.data.map(
								e => (<a href={e.attributes.value}><i className={`fab fa-${e.attributes.label} icon-footer`}></i></a>)
							)
						}
						
						{/* <i className="fab fa-twitter icon-footer ml-3"></i>
						<i className="fab fa-instagram icon-footer ml-3"></i>
						<i className="fab fa-linkedin-in icon-footer ml-3"></i> */}
					</div>
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion") ? <div className="col text-center u-light">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion").attributes.value}</div> : null}
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone") ? <div className="col text-center u-light">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone").attributes.value}</div> : null }
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "email") ? <div className="col text-right u-light">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "email").attributes.value}</div> : null}
				</div>
			</div>

			<div className="container-fluid d-block d-sm-none footer" style={{height: 'auto'}}>
				<div className="row">
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion") ?<div className="col-12 text-center u-light mb-1">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "Direccion").attributes.value}</div> : null}
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone") ? <div className="col-12 text-center u-light mb-1">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "contactPhone").attributes.value}</div> : null}
					{conf && conf.attributes?.contacts?.data.find(e => e.attributes.label === "email") ? <div className="col-12 text-center u-light mb-1">{conf.attributes?.contacts?.data.find(e => e.attributes.label === "email").attributes.value}</div> : null}
					<div className="col-12 text-center mb-1">
						{
							conf && conf.attributes?.social?.data.map(
								(e, i) => (<a href={e.attributes.value} target='_blank' rel="noopener noreferrer"><i className={`fab fa-${e.attributes.label} icon-footer ${i > 0 ? 'ml-3' : ''}`}></i></a>)
							)
						}
					</div>
				</div>	
			</div>
			{
				success && <SweetAlert success title={t('msuccessA')} onConfirm={cerrarSuccess}>{t('msuccessB')}</SweetAlert>
			}
			{
				danger && <SweetAlert danger title={t('merrorA')} onConfirm={cerrarSuccess}>{t('merrorB')}</SweetAlert>
			}
		</>
	);
}

export default withNamespaces()(Page);
